@import '_variables';

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    // background-color: $background-color-body;

    // @include screen-sm {
    //     background-color: $background-color-body-mobile;
    // }
}

body {
    line-height: 1.5;
    font-family: $font-family-poppins;
    color: $color-brand-dark;
    font-size: 87.5%;  // 14px
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjus: 100%;
}

h1, h2, h3, h4, h5, h6 { /* stylelint-disable-line */
    margin: 0;
}

img {
    display: block;
}

a {
    text-decoration: none;
    outline: none;
    color: $color-link;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
@media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}
