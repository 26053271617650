
@import 'styles/_variables';
@import 'styles/_mixins';

.button {
    display: flex !important;
    position: relative;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    align-items: center;
    border-radius: 50px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff !important;
    

    :hover {
        // color: #fff !important
        background-color: unset !important
    }


  
    &__spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
        width: 1.2rem !important;
        height: 1.2rem !important;

        &.text-light {
            color: #79C35A !important;
        }

        .spinner-border {
            vertical-align: unset;
            border-width: 0.15em;
        }
    }
}





