
@import 'styles/_variables';
@import 'styles/_mixins';

.flash-notification {
    // left: 50%;
    // transform: translate(-50%, 0);
    position: fixed;
    bottom: 10px;
    right: 35px;
    // transition: all 200ms ease-out;
    transform: translate(100%, 0);
    will-change: transform;
    opacity: 0;
    min-height: 44px;
    width: 318px;
    z-index: 1500;

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    &.is-visible {
        // transform: translate(0, 0);
        opacity: 1;
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s; /* Firefox < 16 */
         -ms-animation: fadein 0.5s; /* Internet Explorer */
          -o-animation: fadein 0.5s; /* Opera < 12.1 */
             animation: fadein 0.5s;
    }

    &__alert {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        display: flex;

        img {
            align-self: center;
            margin-right: 0.75rem;
            color: #fff;
        }

    }

    .alert-danger{
        color: #FFFFFF;
        background-color: #FE5F55;
        border-color: #FE5F55;
    }

    .alert-success {
        color: #fff;
        background-color: #A6D060;
        border-color: #A6D060;
    }




}
