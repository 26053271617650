
@import 'styles/_variables';
@import 'styles/_mixins';

.offline {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__wrapper {
        height: 100%;
        width: 435px;
        display: flex;
        flex-direction: column;
        padding: $spacing;
        justify-content: space-between;

        img {
            margin-bottom: 5.6rem;
        }

        button {
            margin-top: 4.5rem;
            justify-content: center;
            padding: 12px;
        }
        
        &--top {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    
    &__sub-text {
        padding: 0 30px;
    }
}
