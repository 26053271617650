//-------------------------------------//
// MEDIA QUERIES

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;
$media-xxs: 280px;


//-------------------------------------//
// DIMENSIONS

// navmenu height
$height-navmenu: 60px;

// sidebar
$sidebar-width: 250px;

// border-radius
$border-radius-base: 0;
$border-radius-small: 3px;
$border-radius-medium: 8px;
$border-radius-large: 10px;


//-------------------------------------//
// COLORS

// color: background
$color-background: #fff;

// color: brand
$color-brand-green: #79C35A;
$color-brand-dark: #233452;
$color-brand-light: #737579;
$color-brand-white: #FFFFFF;

// color: font
$color-font-base: $color-brand-dark;

$color-primary: #79C35A;
// $color-secondary: ;
// $color-success: ;
// $color-warning: ;
// $color-info: ;
$color-danger: #d5746d;
// $color-light: #737579;

// colors: links
$color-link: #79C35A;
$color-link-hover: lighten(#0d6efd, 15%);

// color: dividers/borders
$color-divider: #EFEFEF;
$color-border: $color-brand-dark;

// color: modal background
$color-modal-bg: #f5f7f9;

// color: shadow
$color-shadow: rgba(0, 0, 0, 0.11);


//-------------------------------------//
// FONTS

// fonts: base styles
$font-family-poppins: 'Avenir', arial, sans-serif;
$font-size-base: 1rem;

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 600;
$font-bold: 700;


//-------------------------------------//
// SPACING

$spacing: 1.75rem;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;
$spacing-fourth: $spacing * 4;
$spacing-half: calc($spacing / 2);

$page-side-paddings: (
    xl: 70px,
    lg: 50px,
    md: 20px,
    sm: 5px,
    xs: 5px
);


//-------------------------------------//
// Z:INDEX

$zindex-header: 1000;
$zindex-dropdown: 1100;
$zindex-notify: 1300;
$zindex-modal: 1400;
$zindex-overlay: 100;
