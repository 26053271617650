
@import 'styles/_variables';
@import 'styles/_mixins';

.typography {
    color: $color-font-base;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;

    &.size {
        &-40 {
            font-size: 40px;
            line-height: 54px;
        }

        &-32 {
            font-size: 32px;
            line-height: 54px;
        }

        &-26 {
            font-size: 26px;
            line-height: 35px;
        }

        &-24 {
            font-size: 24px;
            line-height: 36px;
        }

        &-22 {
            font-size: 22px;
            line-height: 30px;
        }

        &-20 {
            font-size: 20px;
            line-height: 27px;
        }

        &-18 {
            font-size: 18px;
            line-height: 27px;
        }

        &-16 {
            font-size: 16px;
            line-height: 24px;
        }

        &-15 {
            font-size: 15px;
            line-height: 24px;
        }

        &-14 {
            font-size: 14px;
            line-height: 21px;
        }

        &-12 {
            font-size: 12px;
            line-height: 18px;
        }

        &-11 {
            font-size: 11px;
            line-height: 15px;
        }
    }

    &.color {
        &-green {
            color: $color-brand-green;
        }

        &-dark {
            color: $color-brand-dark;
        }

        &-light {
            color: $color-brand-light;
        }

        &-white {
            color: $color-brand-white;
        }

        &-error {
            color: $color-danger;
        }
    }

    &.weight {
        &-light {
            font-weight: 300;
        }

        &-regular {
            font-weight: 400;
        }

        &-semi-bold {
            font-weight: 600;
        }

        &-bold {
            font-weight: 800;
        }

        // &-extra-bold {
        //     font-weight: 800;
        // }

        // &-black {
        //     font-weight: 900;
        // }
    }
}
