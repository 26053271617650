
@import 'styles/_variables';
@import 'styles/_mixins';

.app-loading {

}

.loading-container {
    margin-top: 0 !important;
    height: 100%;
    padding: $spacing;
    display: flex;
    justify-content: center;
    align-items: center;
}